<script setup lang="ts">
import CiSkeletonItem from '@/components/skeleton/CiSkeletonItem.vue';
import CiSlotContent from '@/components/slots/CiSlotContent.vue';
import DefaultLayout from '@/layouts/default.vue';

/********************
 * COMPOSITIONS      *
 ********************/
const loadingStore = useLoadingStore();
</script>

<template>
  <default-layout>
    <div class="dynamic-page min-h-[995px]">
      <!-- loading -->
      <template v-if="!loadingStore.isLoading('getContent')">
        <CiHeadline class="py-4" />
        <CiSlotContent class="front-1 view-home__content mb-12 mt-6" />
      </template>
      <div
        v-else
        class="container mt-6"
      >
        <div class="row justify-center">
          <div class="col-12 col-lg-9">
            <CiSkeletonItem class="mb-6 h-10" />
            <CiSkeletonItem class="mb-6 h-[400px]" />
            <CiSkeletonItem class="mb-6 h-[400px]" />
            <CiSkeletonItem class="mb-6 h-[400px]" />
            <CiSkeletonItem class="mb-6 h-[400px]" />
          </div>
        </div>
      </div>
    </div>
    <slot />
  </default-layout>
</template>

<style></style>
